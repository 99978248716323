import React, { FunctionComponent } from 'react';
import { DATA_QA } from '../../helpers/data-qa-attributes';
import SearchDateDesign from '../../../../Partials/design-components/search-date/search-date';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SelectInputDesign, { SelectOption } from '../../../../Partials/design-components/select/select';
import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import MultiSportCompetitionListSelect from '../../../../Partials/design-components/select/multisport-competition-list-select';

type Props = {
	selectConfigureData: (date: Date | null, competitionList: SelectOption) => void;
	date: Date;
	selectedCompetitionList: SelectOption;
};

const ConfigureDataSection: FunctionComponent<Props> = ({ selectConfigureData, date, selectedCompetitionList }) => {
	const { t } = useTranslation();
	const featureConfigData: Record<string, any> = featuresService.checkFeatureDataField(FeatureTypes.MULTISPORT_WIDGET_CONFIGURATION);
	const competitionListOptionsFromFeature: string[] = featureConfigData.competitionList || [];
	const mappedCompetitionListData: SelectOption[] = competitionListOptionsFromFeature.map((item: string) => {
		return { name: item, value: item };
	});

	return (
		<Row id={DATA_QA.CONFIGURE_DATE_WRAPPER}>
			<Row>
				<h3> {t('configure_for_date')} </h3>
				<span> {t('configure_for_date_sub')} </span>
			</Row>
			<Row id={DATA_QA.CONFIGURE_COMPETITION_LIST_WRAPPER}>
				<Col style={{ flexGrow: 0 }}>
					<MultiSportCompetitionListSelect
						invokeFunc={selectConfigureData}
						fieldId='competition-list-select'
						labelText={t('configuration_name')}
						placeholderText={t('configuration_name_placeholder')}
						options={mappedCompetitionListData}
						selectedValue={selectedCompetitionList.value}
						date={date}
					/>
				</Col>
				<Col style={{ flexGrow: 0 }}>
					<SearchDateDesign
						invokeFunc={selectConfigureData}
						fieldId='search-config-date'
						date={date}
						labelText={t('date')}
						className={'custom-search-date-wrapper-with-competition-list'}
						selectedCompetitionList={selectedCompetitionList}
					/>
				</Col>
			</Row>
		</Row>
	);
};

export default ConfigureDataSection;
